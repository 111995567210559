export default function useNavlinks() {
  return [
    {
      href: {
        pathname: '/shop',
      },
      label: 'headerDefaultNavlink.shop',
    },
    {
      href: {
        pathname: '/experience',
      },
      label: 'headerDefaultNavlink.experiences',
    },
    {
      href: {
        pathname: '/fai-un-regalo',
      },
      label: 'headerDefaultNavlink.makeAGift',
    },
    // {
    //   href: {
    //     pathname: '/wallet',
    //   },
    //   label: 'headerDefaultNavlink.wallet',
    // },
    {
      href: {
        pathname: '/business/eventi-aziendali/regalistica-aziendale',
      },
      label: 'headerDefaultNavlink.corporateGifting',
    },
  ];
}
